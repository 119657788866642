import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQueenAuth } from "../../Context/QueenAuthContext";

const AdminQueenHeader = () => {
  const { queenAuth, setQueenAuth } = useQueenAuth();

  const handleLogout = () => {
    setQueenAuth({});
    sessionStorage.removeItem("__q_o");
  };
  return (
    <div className="py-1 text-white admin-queen-header-bg w-100">
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Link
              to="/account"
              style={{ fontVariant: "small-caps" }}
              className="fs-4 text-white text-decoration-none next-btn px-2 py-0"
            >
              Queen
            </Link>
            <a
              href="https://ontheway.com.bd/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none pointer  ms-3 next-btn px-2 py-1"
            >
              www.onthe-way.com
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.m360ict.ontheway_queen"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none pointer  ms-3 next-btn px-2 py-1 d-none d-md-block"
            >
              Download App
            </a>
          </div>

          {queenAuth.phone && (
            <button onClick={handleLogout} className="next-btn px-2 py-1">
              Logout
            </button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AdminQueenHeader;
