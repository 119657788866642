import React from "react";

const QueenTale = () => {
  return (
    <div className="my-3">
      <div className="text-center mb-4">
        <img
          width={100}
          src="/assets/admin-queen/menu-logos/queenstale.png"
          alt=""
        />
      </div>
      <div className=" text-center ">
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.on_the_way.queens_tale_part_1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="training-button">Download App</button>
          </a>
        </div>

        <div className="mt-5">
          <a
            href="https://ontheway.com.bd/queens-tale"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="training-button">View website</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QueenTale;
