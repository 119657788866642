import React from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useQueenAuth } from "../../Context/QueenAuthContext";
import { url } from "../../Helpers/Constant";

const ContactUsModal = ({ show, handleClose }) => {
  const { queenAuth } = useQueenAuth();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handelSendMessage = () => {
    setLoading(true);
    fetch(`${url}/api/client/send/contact/msg`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: queenAuth.name,
        email: queenAuth.email,
        phone: queenAuth.phone,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          setMessage("");
          setLoading(false);
          handleClose();
        } else {
          alert(data.message);
          setLoading(false);
        }
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="text-center mt-2">
          <img src="/assets/logo.png" alt="" width="80%" />

          <p className="mt-3">"নারী হোক উদ্যোক্তা"</p>
        </div>
        <div className="mt-4">
          <div className="text-center">
            <p className="fw-bold">HelpLine: +8809638336677</p>
            <p className="fw-bold">Email US: onthewayqueen@gamil.com</p>
            <p className="fw-bold">
              Visit Website:{" "}
              <a
                className="text-decoration-none"
                href="https://ontheway.com.bd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ontheway.com.bd
              </a>
            </p>
            <div>
              <p>
                <span className="fw-bold">Office Location: </span>House: 74
                (D4), Road: 7, Block H, Banani, Dhaka – 1212, Bangladesh
              </p>
            </div>
          </div>
        </div>

        <div className="text-center mt-3">
          <textarea
            type="text"
            className="w-75"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <div>
            {loading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" style={{ color: "#e9313a" }} />
              </div>
            ) : (
              <div className="mt-2">
                <button
                  type="submit"
                  value="Send"
                  className="next-btn"
                  onClick={handelSendMessage}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactUsModal;
